<template>
  <template v-if="checkoutScene != 'Checkout'">
    <!-- 二次下单会同时存在大件提示及商品数量 -->
    <div class="shopping-bag-item__belt-wrap">
      <div 
        v-if="!!goodsStatusInfo.tips"
        ref="tipsWrap"
        class="shopping-bag-item__belt"
        :class="goodsStatusInfo.class"
      >
        <span 
          ref="tipsText"
          class="shopping-bag-item__tips-text"
          v-html="goodsStatusInfo.tips"
        >
        </span>
      </div>
      <div :class="{ 'item-num-welfare': +quantity >= 2 }">
        x{{ quantity }}
      </div>
    </div>
  </template>
  <template v-else>
    <div 
      v-if="!!goodsStatusInfo.tips"
      ref="tipsWrap"
      class="shopping-bag-item__belt-wrap shopping-bag-item__belt"
      :class="goodsStatusInfo.class"
    >
      <span 
        ref="tipsText"
        class="shopping-bag-item__tips-text"
        v-html="goodsStatusInfo.tips"
      >
      </span>
    </div>
  </template>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, nextTick, onMounted, getCurrentInstance, watch } from 'vue'

const instance = getCurrentInstance()

const props = defineProps({
  canEditShoppingbag: {
    type: Boolean,
    default: false
  },
  lagreShipInfo: {
    type: Object,
    default: () => {}
  },
  isOutStockGood: {
    type: Boolean,
    default: false
  },
  quantity: {
    type: [String, Number],
    default: 0
  },
  checkoutScene: {
    type: String,
    default: ''
  },
})

const store = useStore()
const language = computed(() => store.state.language)

const goodsStatusInfo = computed(() => {
  let info = {
    tips: '',
    class: '',
  }

  if(props.checkoutScene != 'Checkout') {
    if(props.lagreShipInfo.isLargeShipGood == 1) { // 大件海陆运
      info = {
        tips: props.lagreShipInfo.largeShipTitle,
        class: 'large-tips',
      }
    }
  } else {
    if(props.canEditShoppingbag) {
      if(props.lagreShipInfo.isLargeShipGood == 1) { // 大件海陆运
        info = {
          tips: props.lagreShipInfo.largeShipTitle,
          class: 'large-tips',
        }
      } else if(props.isOutStockGood) { // 售罄
        info = {
          tips: language.value.SHEIN_KEY_PC_16524,
          class: 'item-num-soldout',
        }
      } 
    } else {
      if(props.isOutStockGood) { // 售罄
        info = {
          tips: language.value.SHEIN_KEY_PC_16524,
          class: 'item-num-soldout',
        }
      } else { // 数量
        info = {
          tips: `x${props.quantity}`,
          class: +props.quantity >= 2 ? 'item-num-welfare' : ''
        }
      }
    }
  }

  return info
})

const handleTextFont = () => {
  nextTick(() => {
    if(typeof window == 'undefined') return

    const textDom = instance?.proxy?.$refs?.tipsText
    if(textDom) {
      const oneLineHeight = 14 // 一行文字的高度
      const textHeight = textDom.getBoundingClientRect().height
      if(textHeight > oneLineHeight) {
        const tipsDom = instance?.proxy?.$refs?.tipsWrap
        tipsDom.style.fontSize = '10px'
      }
    }
  })
}

onMounted(() => {
  handleTextFont()
})

watch(
  () => goodsStatusInfo.value?.tips,
  () => {
    (!!goodsStatusInfo.value?.tips) && handleTextFont()
  },
)
</script>

<style lang="less" scoped>
.shopping-bag-item__belt-wrap {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.50);
  width: 100%;
  padding: 2px 4px;
  bottom: 0;
  text-align: center;
  font-size: 11px;
  color: @sui_color_white;
  font-weight: bold;
  z-index: @zindex-hack;
}
.shopping-bag-item__belt {
  overflow : hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.item-num-welfare {
  color: @sui_color_welfare;
}
.item-num-soldout {
  background-color: rgba(0, 0, 0, .6);
  color: #fff;
}
</style>
