// 运输方式店配集合
export const TRANSPORT_TYPE_SHOP = [
  'shop_transit', //店配
  'express_shoptransit' //快递店配
]

//需要展示环保图标的运输方式
export const TRANSPORT_ENVIRONMENTAL = [
  'express_shoptransit',
  'postage_shoptransit',
  'shop_transit'
]
