class Cache {
  cache = new Map()
  set(key, value) {
    this.cache.set(this.getCacheKey(key), value)
    return
  }
  get(key) {
    return this.cache.get(this.getCacheKey(key))
  }

  getCacheKey(key) {
    if (!key) console.error('key is required')
    return typeof key === 'object' ? JSON.stringify(key) : key
  }
}

export default new Cache()
