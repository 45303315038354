/**
 * @description adyen-fpx支付方式的升级银行app弹窗相关操作
 */
import { computed, reactive, ref, isRef, onMounted, watch } from 'vue'
import {
  setLocalStorage,
  getLocalStorage,
} from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

daEventCenter.addSubscriber({ modulecode: '2-32' })

export default ({
  checkoutBFFAbtInfo = {},
  language = {},
  countDown = 5
}) => {
  let timer = null
  let _resolve = null
  let memberId = ref('')
  // 倒计时
  const _countDown = ref(countDown)
  // 已经显示过提示的 memberId 列表
  const SHOWED_FPX_TIPS_MEMBER_ID_LIST = ref([])
  const UPGRADE_BANK_APP_PROMPT_PAYMENT_LIST = ref([])

  onMounted(() => {
    setMemberId()
    SHOWED_FPX_TIPS_MEMBER_ID_LIST.value = getLocalStorage('SHOWED_FPX_TIPS_MEMBER_ID_LIST') || []
    UPGRADE_BANK_APP_PROMPT_PAYMENT_LIST.value = (window?.gbCommonInfo?.UPGRADE_BANK_APP_PROMPT_PAYMENTS || '').split(',')
    _updateOldMatchCache()
  })

  const setMemberId = () => {
    memberId.value = UserInfoManager.get({ key: 'memberId' }) || ''
  }

  const getCacheKey = (paymentMethod = '') => {
    return `${memberId.value}_${paymentMethod}`
  }
  // 旧逻辑兼容 存了memberId，但是没有存paymentMethod的情况，之前仅adyen-fpx
  const _updateOldMatchCache = () => {
    const cacheKey = getCacheKey('adyen-fpx')
    const oldKeyIndex = SHOWED_FPX_TIPS_MEMBER_ID_LIST.value?.indexOf(memberId.value)
    if (oldKeyIndex > -1) {
      SHOWED_FPX_TIPS_MEMBER_ID_LIST.value.splice(oldKeyIndex, 1, cacheKey)
      setLocalStorage({
        key: 'SHOWED_FPX_TIPS_MEMBER_ID_LIST',
        value: SHOWED_FPX_TIPS_MEMBER_ID_LIST.value,
      })
    }
  }

  /**
   * 是否展示fpx支付方式的升级银行app弹窗
   */
  const isShowFpxTips = computed(() => {
    return (isRef(checkoutBFFAbtInfo) ? checkoutBFFAbtInfo.value : checkoutBFFAbtInfo)?.SheinFpxTips?.param?.FpxTipsShow === 'Show'
  })
  const confirmButtonText = computed(() => {
    return `${(isRef(language) ? language.value.SHEIN_KEY_PC_27383 : language.SHEIN_KEY_PC_27383) || ''}${_countDown.value > 0 ? `（${_countDown.value}S）` : ''}`
  })
  const tips = computed(() => {
    return (isRef(language) ? language.value.SHEIN_KEY_PC_27372 : language.SHEIN_KEY_PC_27372) || ''
  })
  const fpxTipsDialogProps = reactive({
    visible: false,
    tips: tips,
    confirmButtonText: confirmButtonText,
    loaded: false
  })

  watch(() => _countDown.value, (newVal, oldVal) => {
    if (newVal === 0 && oldVal !== 0) {
      window.checkoutEventCenter?.emit('PAYMENT_VALID_SEND_BI', {
        result_reason: ''
      })
    }
  })

  const showFpxTips = (paymentMethod = '') => {
    daEventCenter.triggerNotice({
      daId: '2-32-1',
    })
    fpxTipsDialogProps.loaded = true
    fpxTipsDialogProps.visible = true
    const cacheKey = getCacheKey(paymentMethod)
    SHOWED_FPX_TIPS_MEMBER_ID_LIST.value.push(cacheKey)
    setLocalStorage({
      key: 'SHOWED_FPX_TIPS_MEMBER_ID_LIST',
      value: SHOWED_FPX_TIPS_MEMBER_ID_LIST.value,
    })
    return new Promise((resolve) => {
      _resolve = resolve
      _countDown.value = countDown
      timer = setInterval(() => {
        _countDown.value--
        if (_countDown.value === 0) {
          clearInterval(timer)
          fpxTipsDialogProps.visible = false
          resolve('confirm')
        }
      }, 1000)
    })
  }

  const confirmFpxTips = () => {
    daEventCenter.triggerNotice({
      daId: '2-32-2',
    })
    clearInterval(timer)
    fpxTipsDialogProps.visible = false
    _resolve('confirm')
  }

  const cancelFpxTips = () => {
    daEventCenter.triggerNotice({
      daId: '2-32-3',
    })
    clearInterval(timer)
    fpxTipsDialogProps.visible = false
    _resolve('cancel')
  }

  const checkShowFpxTips = (paymentMethod = '') => {
    const cacheKey = getCacheKey(paymentMethod)
    /**
     * 1. abt开启
     * 2. 在阿波罗配置的可以弹窗提示的支付方式内
     * 3. 没有缓存过
     */
    return isShowFpxTips.value &&
    UPGRADE_BANK_APP_PROMPT_PAYMENT_LIST.value.includes(paymentMethod) &&
    !SHOWED_FPX_TIPS_MEMBER_ID_LIST.value.includes(cacheKey)
  }

  return {
    isShowFpxTips,
    fpxTipsDialogProps,
    checkShowFpxTips,
    showFpxTips,
    confirmFpxTips,
    cancelFpxTips,
  }
}
