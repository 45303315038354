import { TRIPLE_NUMS } from './regexp'
import { isArray } from '@shein/common-function'

// 俄罗斯 Tax Number 计算控制数
// 790153018379俄罗斯测试税号
function getControlNumber({ inn = '', multiplyNumbers = [] }) {
  if (!inn || !multiplyNumbers.length) {
    return
  }
  let total = 0
  for (let i = 0; i < multiplyNumbers.length; i++) {
    total += Number(inn[i]) * multiplyNumbers[i]
  }
  return (total % 11) % 10
}

// 沙特校验 National Id
function checkNationalId(nationalId) {
  let x = 0
  let y = 0
  for (let i = 0; i < nationalId.length; i++) {
    const idNumber = nationalId[i]
    if (i % 2 === 0) {
      // 偶数
      const [tens, digits] = Number(idNumber * 2)
        ?.toString()
        ?.padStart(2, '0')
      x += Number(tens) + Number(digits)
    } else {
      // 奇数
      y += Number(idNumber)
    }
  }

  // 判断余数 0
  const z = (x + y) % 10
  return z === 0
}

/**
 *
 * 沙特校验规则
 * 1、首位数字仅为1、2的10位数字
 * 2、不能包含3位以上（不含）连续相同数字
 * 3、不能与当前地址的电话号码或备用电话号码字段值相同
 * 4、符合 checkNationalId 算法规则
 *
 * */
function artithmeticNationalIdCheck({
  tel = '',
  standbyTel = '',
  nationalId = '',
}) {
  const nums = nationalId.match(TRIPLE_NUMS) || []
  return (
    !nums.length &&
    nationalId != tel &&
    nationalId != standbyTel &&
    checkNationalId(nationalId)
  )
}

function isNumeric(value) {
  return /^\d{1}$/.test(value)
}

function arraySum(array) {
  let sum = 0
  array.map((item) => {
    sum += item
  })
  return sum
}

function validateCpf(value) {
  const cpf = value
  let j = 0
  const num = []
  for (let i = 0; i < cpf.length; i++) {
    if (isNumeric(cpf[i])) {
      num.push(cpf[i])
      j++
    }
  }

  if (j == 0) {
    return false
  }

  if (num.length != 11) {
    return false
  } else {
    for (let i = 0; i < 10; i++) {
      if (
        num[0] == i &&
        num[1] == i &&
        num[2] == i &&
        num[3] == i &&
        num[4] == i &&
        num[5] == i &&
        num[6] == i &&
        num[7] == i &&
        num[8] == i
      ) {
        return false
      }
    }
  }

  j = 10
  let multiplica = []
  for (let i = 0; i < 9; i++) {
    multiplica.push(num[i] * j)
    j--
  }
  let soma = arraySum(multiplica)
  let resto = soma % 11
  let dg = ''
  if (resto < 2) {
    dg = 0
  } else {
    dg = 11 - resto
  }
  if (dg != num[9]) {
    return false
  }

  j = 11
  multiplica = []
  for (let i = 0; i < 10; i++) {
    multiplica[i] = num[i] * j
    j--
  }
  soma = arraySum(multiplica)
  resto = soma % 11
  if (resto < 2) {
    dg = 0
  } else {
    dg = 11 - resto
  }
  if (dg != num[10]) {
    return false
  } else {
    return true
  }
}

function validateCnpj(value) {
  const cnpj = value
  let j = 0
  const num = []
  for (let i = 0; i < cnpj.length; i++) {
    isNumeric(cnpj[i])
    if (isNumeric(cnpj[i])) {
      num[j] = cnpj[i]
      j++
    }
  }

  if (j == 0) {
    return false
  }

  if (num.length != 14) {
    return false
  }

  if (
    num[0] == 0 &&
    num[1] == 0 &&
    num[2] == 0 &&
    num[3] == 0 &&
    num[4] == 0 &&
    num[5] == 0 &&
    num[6] == 0 &&
    num[7] == 0 &&
    num[8] == 0 &&
    num[9] == 0 &&
    num[10] == 0 &&
    num[11] == 0
  ) {
    return false
  } else {
    j = 5
    const multiplica = []
    for (let i = 0; i < 4; i++) {
      multiplica[i] = num[i] * j
      j--
    }
    // let soma = arraySum(multiplica);
    j = 9
    for (let i = 4; i < 12; i++) {
      multiplica[i] = num[i] * j
      j--
    }
    const soma = arraySum(multiplica)
    const resto = soma % 11
    let dg = ''
    if (resto < 2) {
      dg = 0
    } else {
      dg = 11 - resto
    }
    if (dg != num[12]) {
      return false
    }
  }

  j = 6
  const multiplica = []
  for (let i = 0; i < 5; i++) {
    multiplica[i] = num[i] * j
    j--
  }
  // let soma = arraySum(multiplica);
  j = 9
  for (let i = 5; i < 13; i++) {
    multiplica[i] = num[i] * j
    j--
  }
  const soma = arraySum(multiplica)
  const resto = soma % 11
  let dg = ''
  if (resto < 2) {
    dg = 0
  } else {
    dg = 11 - resto
  }
  if (dg != num[13]) {
    return false
  } else {
    return true
  }
}

function isObject(val) {
  return Object.prototype.toString.call(val) == '[object Object]'
}

/**
 * 地址字段转换函数
 * 转换为 shipping、billing 地址，不转换成子级 subsidiary 字段
 * @param {Object} from 必填 原地址数据
 * @param {String} type 必填 转换类型 shippingToEdit、billingToEdit、editToShipping、editToBilling，【方法拆分可拓展逻辑】
 * @return {Object}
 *
 * */
const convertAddress = new (class {
  // 订单地址和编辑页面字段映射
  #SHIPPING_ADDRESS_MAP = Object.entries({
    shipping_country_name: 'country',
    shipping_country_id: 'countryId',
    shipping_firstname: 'fname',
    shipping_lastname: 'lname',
    middle_name: 'middleName',
    shipping_father_name: 'fatherName',
    english_name: 'englishName',
    shipping_province: 'state',
    shipping_state_id: 'stateId',
    shipping_city: 'city',
    shipping_city_id: 'cityId',
    shipping_district: 'district',
    shipping_district_id: 'districtId',
    shipping_street: 'street',
    tax_number: 'taxNumber',
    shipping_address_1: 'address1',
    shipping_address_2: 'address2',
    nationalId: 'nationalId',
    shipping_postcode: 'postcode',
    shipping_telephone: 'tel',
    backupShippingTelephone: 'standbyTel',
    type: 'type',
    birthday: 'birthday',
    shipping_sex: 'sex',
    store_type: 'storeType',
    store_id: 'storeId',
    store_name: 'storeName',
    passport_number: 'passportNumber',
    issue_place: 'passportIssuePlace',
    issue_date: 'passportIssueDate',
    calendar_type: 'calendar_type',
    id_expire_date: 'id_expire_date',
  })
  // 账单地址和编辑页面字段映射
  #BILLING_ADDRESS_MAP = Object.entries({
    firstname: 'fname',
    lastname: 'lname',
    country_name: 'country',
    country_id: 'countryId',
    province: 'state',
    street: 'street',
    city: 'city',
    district: 'district',
    address_1: 'address1',
    address_2: 'address2',
    telephone: 'tel',
    standby_tel: 'standbyTel',
    postcode: 'postcode',
    tax_number: 'taxNumber',
    store_type: 'storeType',
  })
  // 护照信息补全字段映射
  #EXTRA_ADDRESS_MAP = Object.entries({
    country_id: 'countryId',
    fname: 'fname',
    lname: 'lname',
    english_name: 'englishName',
    middle_name: 'middleName',
    national_id: 'nationalId',
    passport_number: 'passportNumber',
    passport_issue_place: 'passportIssuePlace',
    passport_issue_date: 'passportIssueDate',
    tax_number: 'taxNumber',
    birthday: 'birthday',
    calendar_type: 'calendar_type',
    id_expire_date: 'id_expire_date',
  })

  // 订单地址字段转成接口保存地址字段MAP
  #ORDER_ADDRESS_TO_SHIPPING_ADDRESS_MAP = Object.entries({
    shipping_sex: 'sex',
    shipping_firstname: 'fname',
    shipping_lastname: 'lname',
    shipping_telephone: 'tel',
    backupShippingTelephone: 'standby_tel',
    shipping_address_1: 'address1',
    shipping_address_2: 'address2',
    shipping_city: 'city',
    shipping_province: 'state',
    shipping_district: 'district',
    shipping_street: 'street',
    nationalId: 'nationalId',
    shipping_postcode: 'postcode',
    shipping_country_id: 'country_id',
    tax_number: 'tax_number',
    type: 'type',
    shipping_father_name: 'father_name',
    english_name: 'english_name',
    middle_name: 'middle_name',
    shipping_state_id: 'stateId',
    shipping_city_id: 'cityId',
    shipping_district_id: 'districtId',
    store_id: 'store_id',
    passport_number: 'passport_number',
    issue_place: 'passport_issue_place',
    issue_date: 'passport_issue_date',
    birthday: 'birthday',
    shipping_country_name: 'country',
    store_type: 'storeType',
    store_name: 'storeName',
    calendar_type: 'calendar_type',
    id_expire_date: 'id_expire_date',
  })

  // 订单地址字段转成修改或者保存接口需要字段
  #ORDER_ADDRESS_TO_INTERFACE_MAP = Object.entries({
    shipping_sex: 'sex',
    shipping_firstname: 'fname',
    shipping_lastname: 'lname',
    shipping_telephone: 'tel',
    backupShippingTelephone: 'standby_tel',
    shipping_address_1: 'address1',
    shipping_address_2: 'address2',
    shipping_city: 'city',
    shipping_province: 'state',
    shipping_district: 'district',
    shipping_street: 'street',
    nationalId: 'nationalId',
    shipping_postcode: 'postcode',
    shipping_country_id: 'country_id',
    tax_number: 'tax_number',
    type: 'type',
    shipping_father_name: 'father_name',
    english_name: 'english_name',
    middle_name: 'middle_name',
    shipping_state_id: 'stateId',
    shipping_city_id: 'cityId',
    shipping_district_id: 'districtId',
    passport_number: 'passport_number',
    issue_place: 'passport_issue_place',
    issue_date: 'passport_issue_date',
    birthday: 'birthday',
    shipping_country_name: 'country_name',
    calendar_type: 'calendar_type',
    id_expire_date: 'id_expire_date',
    address_id: 'address_id',
    lat: 'lat',
    lng: 'lng',
    company: 'company',
    fax: 'fax',
    is_default: 'is_default',
    phone_code: 'phone_code',
    order_email: 'order_email',
    tag: 'tag',
    // shipping_nullity: 'country_value',
  })

  // 订单地址附属字段转换
  #ORDER_SUBSIDIARY_MAP = Object.entries({
    shipping_address_type: 'address_type',
    shipping_english_name: 'english_name',
    shipping_father_name: 'father_name',
    shipping_issue_date: 'issue_date',
    shipping_issue_place: 'issue_place',
    shipping_middle_name: 'middle_name',
    shipping_passport_number: 'passport_number',
    store_id: 'store_id',
    store_name: 'store_name',
    store_type: 'store_type',
    transport_type: 'transport_type',
  })

  constructor() {
    this.action = this.action.bind(this)
  }
  action({ from = {}, type = 'shippingToEdit' }) {
    if (!isObject(from) || !type) {
      return {}
    }
    return Object.fromEntries(this[type](from))
  }
  shippingToEdit(from) {
    const to = this.#SHIPPING_ADDRESS_MAP.map(([pre, post]) => {
      return [post, from[pre] || from?.subsidiary?.[pre] || '']
    })
    return to
  }
  billingToEdit(from) {
    const to = this.#BILLING_ADDRESS_MAP.map(([pre, post]) => {
      return [post, from[pre] || from?.subsidiary?.[pre] || '']
    })
    return to
  }
  editToShipping(from) {
    const to = this.#SHIPPING_ADDRESS_MAP.map(([pre, post]) => {
      return [pre, from[post] || '']
    })
    return to
  }
  editToBilling(from) {
    const to = this.#BILLING_ADDRESS_MAP.map(([pre, post]) => {
      return [pre, from[post] || '']
    })
    return to
  }
  extraToAddress(from) {
    const to = this.#EXTRA_ADDRESS_MAP.map(([pre, post]) => {
      return [post, from[pre] || '']
    })
    return to
  }
  orderToShipping(from) {
    const to = this.#ORDER_ADDRESS_TO_SHIPPING_ADDRESS_MAP.map(
      ([pre, post]) => {
        return [post, from[pre] || from?.subsidiary?.[pre] || '']
      }
    )
    return to
  }
  orderToInterface(from) {
    const to = this.#ORDER_ADDRESS_TO_INTERFACE_MAP.map(([pre, post]) => {
      return [post, from[pre] || from?.subsidiary?.[pre] || null]
    })
    return to
  }
  orderToSubsidiary(from) {
    const to = this.#ORDER_SUBSIDIARY_MAP.map(([pre, post]) => {
      return [post, from[pre] || '']
    })
    return to
  }
})().action

const sortAddressCardInfo = new (class {
  #CARD_ORDER = {
    name: {
      addressbook: {
        // Taiwan
        209: ['lname', 'fname'],
        // Japan
        108: ['lname', 'fname'],
        // default
        default: ['fname', 'middleName', 'lname', 'fatherName'],
      },
      shipping: {
        // Japan
        108: [
          'shipping_lastname',
          'shipping_firstname',
        ],
        // default
        default: ['shipping_firstname', 'shipping_lastname'],
      },
    },
  }
  constructor() {
    this.action = this.action.bind(this)
  }
  action({
    detail = {},
    type = 'name',
    gap = ' ',
    fromType = 'addressbook',
    needFilter = true,
  } = {}) {
    if (!Object(detail) || !Object.keys(detail)?.length) {
      return ''
    }
    const cardOrder = this.#CARD_ORDER?.[type]?.[fromType] || {}
    const countryId = detail.countryId || detail.shipping_country_id
    const order = cardOrder?.[countryId] || cardOrder?.default
    if (!isArray(order)) {
      return ''
    }
    if (needFilter) {
      return order
        .map((item) => detail[item] || detail?.subsidiary?.[item])
        .filter(Boolean)
        .join(gap)
    } else {
      return order.map((item) => {
        if (detail[item]) {
          return detail[item]
        } else if (detail?.subsidiary?.[item]) {
          return detail?.subsidiary?.[item]
        } else {
          return ''
        }
      })
    }
  }
})().action

export {
  getControlNumber,
  checkNationalId,
  artithmeticNationalIdCheck,
  validateCpf,
  validateCnpj,
  isObject,
  convertAddress,
  sortAddressCardInfo,
}
